import { agreeCookies } from '../../../common/scripts/cookieLaw'

function buildProcessUrl(baseUrl, params) {
  const url = new URL(baseUrl)
  const searchParams = new URLSearchParams(window.location.search)
  const urlParams = new URLSearchParams({
    ...Object.fromEntries(url.searchParams),
    ...Object.fromEntries(searchParams),
    ...Object.fromEntries(params)
  });
  return `${url.origin}${url.pathname}?${urlParams}`
}

function setupProductBlock(container) {
  const products = container.getElementsByClassName('product-block')

  if (products.length == 0) {
    return
  }

  const bgs = document.querySelectorAll('.html-background')
  const desktopProductSelectionButtons = document.getElementsByClassName('products-selection-tab')
  const heroText = document.querySelector('.hero-text')

  let activeProductIndex = 0
  let initialLoad = true

  function isMobile() {
    return window.innerWidth < 768
  }

  function reCalculateProductDisplayOnWindowResize() {
    if (isMobile()) {
      initProducts()
    } else {
      if (heroText.style.height !== '') heroText.style.height = ''
      initDesktopProductsSelection()
    }
  }

  function activateProduct(panel, i) {
    bgs.forEach(element => {
      element.classList.remove('active')
    })
    document.querySelectorAll('.html-bg-' + i).forEach(element => {
      element.classList.add('active')
    })
    panel.style.maxHeight = initialLoad ? '100%' : panel.scrollHeight + 'px'
    activeProductIndex = i

    let applicationPrincipal = products[i].dataset.applicationPrincipal

    const submitButton = panel.querySelector('.product-button')

    if (submitButton) {
      if (submitButton.classList.contains('product-button-link')) {
        const params = new URLSearchParams();
        params.append('loanType', products[i].dataset.product)
        params.append('applicationPrincipal', applicationPrincipal)
        submitButton.setAttribute('href', buildProcessUrl(products[i].dataset.url, params))
      }

      submitButton.addEventListener('click', function() {
        if (this.loading) {
          return
        }

        this.loading = true
        agreeCookies()

        if (submitButton.classList.contains('product-button-link')) {
          return
        }

        const urlSearchParams = new URLSearchParams(window.location.search)
        if (products[i].dataset.product) urlSearchParams.set('loanType', products[i].dataset.product)
        if (applicationPrincipal) urlSearchParams.set('applicationPrincipal', applicationPrincipal)
        if (products[i].dataset.xps) urlSearchParams.set('xps', products[i].dataset.xps)
        window.removeEventListener('resize', reCalculateProductDisplayOnWindowResize)
        window.location.href = '/process?' + urlSearchParams.toString()
      })
    }

    const rangeSliderElement = panel.querySelector('fi-range-slider')
    if (rangeSliderElement) {
      rangeSliderElement.addEventListener('fiInput', function(event) {
        applicationPrincipal = event.detail.toString()

        if (!submitButton.classList.contains('product-button-link')) {
          return
        }

        const params = new URLSearchParams();
        params.append('loanType', products[i].dataset.product)
        params.append('applicationPrincipal', applicationPrincipal)

        submitButton.setAttribute('href', buildProcessUrl(products[i].dataset.url, params))
      })
    }

    if (!initialLoad && isMobile()) {
      scrollIntoViewIfNeeded(products[i])
    }

    for (let j = 0; j < products.length; j++) {
      if (products[i].classList !== products[j].classList) {
        products[j].classList.remove('active')
        const activeApr = document.querySelector(`.apr-${products[j].dataset.product}`)
        if (activeApr) {
          activeApr.classList.remove('active')
        }
        products[j].querySelector('.product-panel').style.maxHeight = null
      }
    }

    const activeApr = document.querySelector(`.apr-${products[i].dataset.product}`)
    if (activeApr) {
      activeApr.classList.add('active')
    }

    initialLoad = false
  }

  function scrollIntoViewIfNeeded(target) {
    if (target.getBoundingClientRect().bottom > window.innerHeight) {
      target.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    }
  }

  function calculateTopPadding() {
    if (!isMobile()) {
      return
    }

    if (heroText.classList.contains('hero-text-fixed-height')) {
      return
    }

    const productsContainer = document.querySelector('.products')

    // there is no products container on blog page AND we don't need to calculate top padding for products there.
    if (!productsContainer) {
      return
    }

    const productsTop = productsContainer.getBoundingClientRect().top
    const activeProductHeight = products[0].offsetHeight
    const buttonHeight = 70
    let sliderHeight = 0

    if (products[0].querySelector('fi-range-slider')) {
      sliderHeight = 47
    }

    // 16 margins
    // 80 closed product height
    const calculatedPadding = window.innerHeight - productsTop - activeProductHeight - 16 - 80 - buttonHeight - sliderHeight
    const heroTextHeight = heroText.getBoundingClientRect().height
    const heroTextCalculatedHeight = heroTextHeight + calculatedPadding
    heroText.style.height = heroTextCalculatedHeight < 124 || heroTextCalculatedHeight > 500 ? '124px' : heroTextCalculatedHeight + 'px'
  }

  function productClickOnMobileListener(event, i) {
    const panel = products[i].querySelector('.product-panel')

    if (event.composedPath().includes(panel) || products[i].classList.contains('active')) {
      return
    }

    products[i].classList.toggle('active')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
      bgs[0].classList.add('active')
    } else {
      activateProduct(panel, i)
    }
  }

  function initProducts() {
    for (let i = 0; i < products.length; i++) {
      if (initialLoad) {
        products[i].addEventListener('click', (event) => productClickOnMobileListener(event, i))
      }
    }
  }

  function desktopProductSelectionOnClickListener(event, i) {
    if (desktopProductSelectionButtons[i].classList.contains('active')) {
      return
    }
    desktopProductSelectionButtons[i].classList.toggle('active')

    for (let j = 0; j < desktopProductSelectionButtons.length; j++) {
      if (desktopProductSelectionButtons[i].classList !== desktopProductSelectionButtons[j].classList) {
        desktopProductSelectionButtons[j].classList.remove('active')
      }
    }
    products[i].classList.toggle('active')
    const panel = products[i].querySelector('.product-panel')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
      bgs[0].classList.add('active')
    } else {
      activateProduct(panel, i)
    }
  }

  function initDesktopProductsSelection() {
    for (let i = 0; i < desktopProductSelectionButtons.length; i++) {
      if (activeProductIndex === i) {
        desktopProductSelectionButtons[i].classList.add('active')
      } else {
        desktopProductSelectionButtons[i].classList.remove('active')
      }

      if (initialLoad) {
        desktopProductSelectionButtons[i].addEventListener('click', () => desktopProductSelectionOnClickListener(event, i))
      }
    }
  }

  function hideExpandableIconWithOneProduct() {
    if (products.length === 1) {
      const arrow = products[0].querySelector('.arrow')
      if (arrow !== null) {
        arrow.style.display = 'none'
      }
    }
  }

  function setProductsLoading(loading) {
    for (let i = 0; i < products.length; i++) {
      const panel = products[i].querySelector('.product-panel')
      if (panel) {
        const button = products[i].querySelector('.product-panel').querySelector('fi-button')
        if (button) {
          button.loading = loading
        }
      }
    }
  }

  initProducts()
  initDesktopProductsSelection()
  hideExpandableIconWithOneProduct()
  calculateTopPadding()

  const panel = products[0].querySelector('.product-panel')
  activateProduct(panel, 0)
  setProductsLoading(false)

  window.addEventListener('resize', reCalculateProductDisplayOnWindowResize)

  window.onunload = function() {
  }

  // subscribe to visibility change events
  document.addEventListener('visibilitychange', function() {
    if (document.visibilityState === 'hidden') {
      setProductsLoading(false)
    }

    if (document.visibilityState === 'visible') {
      setProductsLoading(false)
    }
  })

}

document.addEventListener('DOMContentLoaded', () => {
  const blocks = document.getElementsByClassName('product-blocks')

  if (blocks.length == 0) {
    return
  }

  for (let e of blocks) {
    setupProductBlock(e)
  }
})
